<template>
  <div v-if="showLoader" id="loader-wrap">
    <div class="loader">
      <div class="loader-content">
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p class="mt-3">Načítám...</p>
      </div>
    </div>
  </div>
  <RouterView :settings="settings"/>
</template>

<script setup>
import {RouterView} from 'vue-router';
import http from '@/http';
</script>

<script>
import TenantService from "@/services/tenant-service.js";

export default {
  data() {
    return {
      availableLocales: this.$i18n.availableLocales,
      settings: {
        primaryColor: '#004148',
        primaryInterfaceColor: '#004148'
      },
      showLoader: true,
      textColor: '#000'
    };
  },

  created() {
    this.getSettings();
    this.getLangToBeUsed();
  },

  mounted() {
    // check TTL token
  },

  computed: {
    /*primaryColor() {
      return this.tenant_settings.primaryColor;
    },*/
  },

  methods: {
    getLangToBeUsed() {
      let lang = this.$route.query.locale;

      if (lang && this.availableLocales.includes(lang)) {
        this.setAppLang(lang);
      }
    },

    setAppLang(lang, callback) {
      // set lang for translations plugin
      this.$i18n.locale = lang;
      if (typeof callback == 'function') {
        callback();
      }
    },

    updateTheme() {
      const root = document.querySelector('body');
      root.style.setProperty('--t-primary-color', this.settings.primaryColor);
      root.style.setProperty('--t-primary-interface-color', this.settings.primaryInterfaceColor);
      root.style.setProperty('--t-sidebar-text-color', this.textColor);
    },

    getSettings() {
      let self = this;
      let tenant_code = this.$route.query.tenant_code;
      window.tenant_code = tenant_code;

      http.get('get-tenant-settings?tenant_code=' + tenant_code).then((response) => {
        if (response.data.tenant_settings) {
          self.settings = response.data.tenant_settings;
        } else {
          self.settings = TenantService.getTenantSettings('default');
        }
        window.settings = self.settings;
        self.showLoader = false;
        this.themeSettings = self.settings;
        self.countSidebarTextColor();
        self.updateTheme();
      });
    },

    countSidebarTextColor() {
      let color = window.settings.primaryInterfaceColor;
      let hexcolor = color.replace('#', '');

      // Převod hexadecimální hodnoty na decimální
      var r = parseInt(hexcolor.substring(0, 2), 16);
      var g = parseInt(hexcolor.substring(2, 4), 16);
      var b = parseInt(hexcolor.substring(4, 6), 16);

      // Výpočet YIQ
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      this.textColor = (yiq >= 128) ? '#000' : '#fff';
    },
  },
};
</script>

<style lang="scss">
#loader-wrap .loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


html {
  --bs-primary: v-bind('settings.primaryColor');
  --bs-secondary: #6c757d;
  --bs-terciary: #d18e6a;
  --bs-heading-color: v-bind('settings.primaryColor');
  --bs-btn-active-bg: v-bind('settings.primaryColor');
  --bs-primary-bg: #cfe2ff;
  --bs-input-hover-color: v-bind('settings.primaryColor');

  .btn-primary {
    --bs-btn-bg: v-bind('settings.primaryColor');
    --bs-btn-hover-bg: v-bind('settings.secondaryMenuColor');
    --bs-btn-border-color: v-bind('settings.primaryColor');
  }

  .btn-outline-primary {
    --bs-btn-color: v-bind('settings.primaryColor');
    --bs-btn-border-color: v-bind('settings.primaryColor');
    --bs-btn-hover-bg: v-bind('settings.secondaryMenuColor');
    --bs-btn-active-bg: v-bind('settings.primaryColor');
    --bs-btn-active-border-color: v-bind('settings.primaryColor');
  }

  .form-check-input {
    --bs-form-check-bg: v-bind('settings.primaryColor');
    background-color: v-bind('settings.primaryColor');
    border: var(--bs-border-width) solid v-bind('settings.primaryColor');
  }

  .form-check-input:checked {
    background-color: v-bind('settings.primaryColor');
    border-color: v-bind('settings.primaryColor');
    --bs-btn-color: v-bind('settings.primaryColor') !important;
    --bs-form-check-bg: v-bind('settings.primaryColor') !important;
    --bs-form-check-checked-bg: v-bind('settings.primaryColor') !important;
    --bs-btn-active-bg: v-bind('settings.primaryColor') !important;
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    --bs-btn-color: v-bind('settings.primaryColor');
    --bs-form-check-bg: v-bind('settings.primaryColor');
    --bs-form-check-checked-bg: v-bind('settings.primaryColor');
    --bs-btn-active-bg: v-bind('settings.primaryColor');
  }

  .form-range::-webkit-slider-thumb {
    background-color: v-bind('settings.primaryColor');
  }

  .sidebar {
    background-color: v-bind('settings.primaryInterfaceColor');
  }

  .page-heading {
    h3 {
      color: v-bind('settings.primaryColor');
    }
  }

  .generate-offer-button {
    background-color: v-bind('settings.primaryColor');
  }

  .nav .nav-item .nav-link {
    color: v-bind('settings.primaryColor');
  }

  .breadcrumb .breadcrumb-item a {
    color: v-bind('settings.primaryColor');
    text-decoration: none;
  }

  .pages-list {
    .pages-list-item {
      color: v-bind('settings.primaryColor');
    }
  }
}

@import './assets/scss/styles.scss';
@import 'bootstrap-icons/font/bootstrap-icons.css';

</style>
