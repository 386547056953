<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('pages.pages') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <!--      <div class="filter">
              <div class="form-group filter-search-input">
                <input v-on:keyup="onSearch" class="form-control" :placeholder="$t('items_list.searchItem')"
                       v-model="serverParams.searchTerm"/>
                <span class="icon"><i class="bi bi-search"></i></span>
              </div>
            </div>-->
      <div class="mt-4 text-end" v-show="editAllowed">
        <button
            class="btn btn-primary"
            @click="openModal"
        ><i class="bi bi-file-earmark-plus"></i
        ><span class="ps-2">{{ $t('page_admin.addPage') }}</span></button>
      </div>
      <ul class="pages-list pages-list--big">
        <li v-for="page in pages" :key="page.slug">
          <router-link :to="{ name: 'PagesDetailView', params: { slug: page?.slug } }" class="pages-list-item">
            <div class="pages-list-item-title-wrap">
              <span class="pages-list-item-title">{{ page.title }} <i class="bi bi-eye-slash" v-if="!page.is_visible"></i><i class="bi bi-chevron-right"></i></span>
            </div>
            <p class="pages-list-item-description">{{ page.description }}</p>
          </router-link>
        </li>
      </ul>
      <!--      <div class="mt-4">
              <vue-good-table
                  :columns="columns"
                  :rows="pages"
                  :pagination-options="{
                enabled: true,
                perPage: 20,
                position: 'bottom',
                nextLabel: $t('general.next'),
                prevLabel: $t('general.previous'),
                perPageDropdownEnabled: false,
              }"
                  :sort-options="{
                enabled: true,
              }"
              >
                <template #emptystate>
                  <div v-if="!isLoading" style="font-weight: bold; text-align: center">
                    {{ $t('general.noData') }}
                  </div>
                  <div v-if="isLoading" style="font-weight: bold; text-align: center">
                    {{ $t('general.loadingData') }}
                  </div>
                </template>
                &lt;!&ndash; <template #loadingContent>
                  <div style="font-weight: bold; text-align: center">
                    Nahrávají se data.
                  </div>
                </template> &ndash;&gt;
                <template #table-row="props">
                   <span v-if="props.column.field == 'is_visible'">
                     <span v-if="props.row.is_visible">{{ $t('general.' + props.row.is_visible) }}</span></span>

                  <span v-if="props.column.field == 'action'" style="font-weight: bold; float: right">
                    <button
                        class="btn btn-primary me-2"
                        @click="openModal(props.row.id)"
                    ><i class="bi bi-pencil"></i
                    ></button>
                    &lt;!&ndash;             <router-link
                                     class="btn btn-primary"
                                     :to="{ name: 'PageAdminDetailView', params: { id: props.row.id } }"
                                     :title="$t('page_admin.showDetail')"
                                 ><i class="bi bi-eye"></i
                                 ></router-link>&ndash;&gt;
                    <button type="button" class="btn btn-primary ms-2" @click="deletePage(props.row.id)">
                                          <i class="bi bi-trash"/></button>

                </span>
                </template>
              </vue-good-table>
            </div>-->
    </div>
    <Modal ref="PageAdminModal" :pages="pages"/>
  </div>
</template>

<script>
import http from '@/http';
import Modal from "@/components/page_admin/PageAdminModal.vue";
import TokenService from "@/services/token-service.js";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      pages: [],
      /*columns: [
        {
          label: this.$t('page_admin.title'),
          field: 'title',
          formatFn: this.formatPageColumn,
        },
        {
          label: this.$t('page_admin.description'),
          field: 'description',
        },
        {
          label: this.$t('page_admin.visible'),
          field: 'is_visible',
        },
        {
          label: '',
          field: 'action',
          html: true,
          sortable: false,
        },
      ],*/
      isLoading: false,
      editAllowed: false,
    };
  },

  mounted() {
    this.loadPages();
    this.emitter.on('pageSaved', (page) => {
      this.loadPages();
    });
    this.canEdit();

    /*setTimeout(function(){
      http.post('/auth/me').then((response) => {
        console.log(response);
      });
    }, 2000);*/
  },

  methods: {
    loadPages() {
      let self = this;
      self.isLoading = true;
      http.get('/pages').then((response) => {
        self.pages = response.data.pages.filter(page => page.parent_id === null);
        self.isLoading = false;
      });
    },

    openModal(id) {
      let page = this.pages.find(page => page.id === id);
      this.$refs.PageAdminModal.toggleModal(page);
    },

    canEdit() {
      this.editAllowed = TokenService.getRoleLevel() === 5;
    }
    /*
    deletePage(id) {
      http.post('/pages/' + id + '/delete')
          .then(response => {
            let index = this.pages.findIndex(page => page.id === id);
            if (index !== -1) {
              this.pages.splice(index, 1);
            }
          })
          .catch(error => {
            console.error(error);
          });
    },

    formatPageColumn(value, row) {
      const depth = row.depth || 0;
      const indent = '-'.repeat(depth * 3);
      return `${indent} ${value}`;
    }*/
  },
};
</script>
