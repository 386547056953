<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('items_list.itemsList') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <!--      <div class="filter">
              <div class="form-group filter-search-input">
                <input v-on:keyup="onSearch" class="form-control" :placeholder="$t('items_list.searchItem')"
                       v-model="serverParams.searchTerm"/>
                <span class="icon"><i class="bi bi-search"></i></span>
              </div>
            </div>-->
      <div class="card mt-4">
        <div class="card-body">
          <div class="filter">
            <div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div>
                    <label class="form-label">{{ $t('items_list.name') }}:</label>
                    <input class="form-control filter-input" v-model="filters.name" @keyup="onSearch">
                  </div>
                  <div class="mt-3">
                    <label class="form-label">{{ $t('items_list.ISIN') }}:</label>
                    <input class="form-control filter-input" v-model="filters.isin" @keyup="onSearch">
                  </div>
                  <div class="mt-3">
                    <label class="form-label">{{ $t('items_list.code') }}:</label>
                    <input class="form-control filter-input" v-model="filters.code" @keyup="onSearch">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mt-md-0 mt-3">
                    <label class="form-label">{{ $t('items_list.currency') }}:</label>
                    <!--                    <select class="form-select" v-model="filters.currency">
                                          <option v-for="(currency, index) in filtersOptions.currencies" :key="index">
                                            {{ currency }}
                                          </option>
                                        </select>-->
                    <VueMultiselect
                        v-model="filters.currency"
                        :options="filtersOptions.currencies"
                        :object="false"
                        :close-on-select="false"
                        :show-labels="true"
                        :allow-empty="true"
                        :multiple="true"
                        :preserve-search="true"
                        :max="10"
                        :select-label="$t('items_list.choose')"
                        :selected-label="$t('items_list.selected')"
                        :deselect-label="$t('items_list.deselect')"
                        :placeholder="$t('items_list.select')"
                        @select="onSearch"
                        @remove="onSearch"
                    >
                    </VueMultiselect>

                  </div>
                  <div class="mt-3">
                    <label class="form-label">{{ $t('items_list.country') }}:</label>
                    <VueMultiselect
                        v-model="filters.country"
                        :options="filtersOptions.countries"
                        :object="false"
                        :close-on-select="false"
                        :show-labels="true"
                        :allow-empty="true"
                        :multiple="true"
                        :preserve-search="true"
                        :max="10"
                        :select-label="$t('items_list.choose')"
                        :selected-label="$t('items_list.selected')"
                        :deselect-label="$t('items_list.deselect')"
                        :placeholder="$t('items_list.select')"
                        @select="onSearch"
                        @remove="onSearch"
                    >
                    </VueMultiselect>
                  </div>
                  <div class="mt-3">
                    <label class="form-label">{{ $t('items_list.type') }}:</label>
                    <VueMultiselect
                        v-model="filters.type"
                        :options="filtersOptions.types"
                        :object="false"
                        :close-on-select="false"
                        :show-labels="true"
                        :allow-empty="true"
                        :multiple="true"
                        :preserve-search="true"
                        :max="10"
                        :select-label="$t('items_list.choose')"
                        :selected-label="$t('items_list.selected')"
                        :deselect-label="$t('items_list.deselect')"
                        :placeholder="$t('items_list.select')"
                        @select="onSearch"
                        @remove="onSearch"
                    >
                    </VueMultiselect>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <vue-good-table
            mode: remote
            :columns="columns"
            :rows="rows"
            :total-rows="totalRows"
            :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 20,
          position: 'bottom',
          nextLabel: $t('general.next'),
          prevLabel: $t('general.previous'),
          perPageDropdownEnabled: false,
        }"
            :sort-options="{
          enabled: true,
        }"
            v-on:page-change="onPageChange"
            v-on:sort-change="onSortChange"
            v-on:column-filter="onColumnFilter"
            v-on:per-page-change="onPerPageChange"
        >
          <template #emptystate>
            <div v-if="!isLoading" style="font-weight: bold; text-align: center">
              {{ $t('general.noData') }}
            </div>
            <div v-if="isLoading" style="font-weight: bold; text-align: center">
              {{ $t('general.loadingData') }}
            </div>
          </template>
          <!-- <template #loadingContent>
            <div style="font-weight: bold; text-align: center">
              Nahrávají se data.
            </div>
          </template> -->
          <template #table-row="props">
          <span v-if="props.column.field == 'action'">
            <span v-if="true || props.row.own_product" style="font-weight: bold; float: right">
              <router-link
                  class="btn btn-primary"
                  :to="{ name: 'ItemsDetailView', params: { code: props.row.code }, query: queryString }"
                  :title="$t('items_list.showDetail')"
              ><i class="bi bi-eye"></i
              ></router-link>
            </span>
          </span>
            <span v-if="props.column.field === 'temp_yield_from_start'">
              <span v-html="formatPercentageWithArrow(props.row.temp_yield_from_start)"></span>
            </span>
            <span v-if="props.column.field === 'yield_current_year'">
              <span v-html="formatPercentageWithArrow(props.row.yield_current_year)"></span>
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  data() {
    return {
      queryString: {
        tenant_code: window.tenant_code ?? '',
      },
      columns: [
        {
          label: this.$t('items_list.code'),
          field: 'code',
        },
        {
          label: this.$t('items_list.name'),
          field: 'name',
        },
        {
          label: this.$t('items_list.type'),
          field: 'type',
        },
        {
          label: this.$t('items_list.ISIN'),
          field: 'isin',
        },
        {
          label: this.$t('items_list.currency'),
          field: 'currency',
        },
        {
          label: this.$t('items_list.country'),
          field: 'country',
        },
        {
          label: this.$t('items_list.yieldFromStart'),
          field: 'temp_yield_from_start',
        },
        {
          label: this.$t('items_list.yieldCurrentYear'),
          field: 'yield_current_year',
        },
        {
          label: '',
          field: 'action',
          html: true,
          sortable: false,
        },
      ],
      rows: [],
      totalRows: 0,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 20,
        //searchTerm: '',
        filters: '',
        tenant_code: window.tenant_code ?? '',
      },
      searchTimeout: null,
      searchTimeoutLimit: 400,
      filters: {
        name: '',
        isin: '',
        code: '',
        currency: [],
        country: [],
        type: [],
      },
      filtersOptions: {
        currencies: [],
        countries: [],
        types: [],
      },
    };
  },

  mounted() {
    setTimeout(() => {
      this.loadItems();
    }, 500);
    this.getFilterOptions();
  },

  methods: {
    loadItems() {
      let self = this;
      self.isLoading = true;
      http.get('/items', {
        params: {
          ...this.serverParams
        }
      }).then((response) => {
        self.rows = response.data.items;
        self.totalRows = response.data.totalItems;
        self.isLoading = false;
      });
    },

    buildFiltersQuery() {
      if (this.filters) {
        let queryString = new URLSearchParams(this.filters).toString();
        this.serverParams.filters = queryString;
      }
    },

    getFilterOptions() {
      let self = this;
      http.get('/items/get-filter-options').then((response) => {
        self.filtersOptions = response.data;
      });
    },

    /**
     * methods to manipulate with params
     */
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    /**
     * events when manipulated with table
     */
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.serverParams.page = 1;
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type,
        },
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.serverParams.page = 1;
      this.updateParams(params);
      this.loadItems();
    },

    onSearch() {
      this.serverParams.page = 1;
      let params = this.serverParams;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      let self = this;
      this.searchTimeout = setTimeout(function () {
        self.buildFiltersQuery();
        self.updateParams(params);
        self.loadItems();
        clearTimeout(self.searchTimeout);
      }, this.searchTimeoutLimit);
    },

    formatPercentage: function (value) {
      return value ? parseFloat(value).toFixed(2) + ' %' : '---';
    },

    formatPercentageWithArrow: function (value) {
      if (value === null || value === undefined) {
        return null;
      }

      let formattedValue = parseFloat(value).toFixed(2) + ' %';

      if (value > 0) {
        formattedValue += '<span class="text-success"> <i class="bi bi-arrow-up"></i></span>';
      } else if (value < 0) {
        formattedValue += '<span class="text-danger"> <i class="bi bi-arrow-down"></i></span>';
      }

      return formattedValue;
    },
    /**
     * end of events when manipulated with table
     */
  },
};
</script>
