import axios from 'axios';
import authHeader from './services/auth-header';
/* import TokenService from './services/token-service'; */

let baseURL = null;

if (window.location.host != "inequity.lan:3001") {
    baseURL = 'https://api.produkty.in-equity.cz/api';
} else baseURL = 'http://api.inequity.lan:8001/api';


//
if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}
//

let myAxios = axios.create({
    baseURL: baseURL,
    headers: authHeader()
});

myAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            axios.defaults.headers.common['Authorization'] = 'Bearer';
            //router.push({name: 'Login'});
        }
        return Promise.reject(error);
    }
);

/* myAxios.interceptors.response.use(function (response) {
    if (response.status == 200 && response.data && response.data.message && response.data.message.length > 0) {
        Swal.fire({
            type: 'success',
            icon: 'success',
            text: response.data.message
        });
    }

    return response;
}, async function (error) {
    if (error.response) {
        let response = error.response;
        const code = response.status;
        const errorMessage = response.data.message;
        const originalConfig = error.config;

        if(response.data.message) {
            Swal.fire({
                type: 'error',
                message: response.data.message
            });
        }

    }
    return Promise.reject(error);
}); */

export default myAxios;
