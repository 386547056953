import {createRouter, createWebHistory} from 'vue-router';
import LoginView from '../views/auth/LoginView.vue';
import ItemsChartView from '../views/items/ItemsChartView.vue';
import ItemsChartEmbedView from '../views/items/ItemsChartEmbedView.vue';
import ItemsCalculatorView from '../views/items/ItemsCalculatorView.vue';
import ItemsDetailView from '../views/items/ItemsDetailView.vue';
import ItemsIndexView from '../views/items/ItemsIndexView.vue';
/*import TenantsIndexView from '../views/tenants/TenantsIndexView.vue';
import UsersIndexView from '../views/users/UsersIndexView.vue';*/
import OffersView from '../views/offers/OffersView.vue';
import CalculatorsView from '../views/calculators/CalculatorsView.vue';
import PageAdminView from '../views/page-admin/PageAdminView.vue';
import PageAdminDetailView from '../views/page-admin/PageAdminDetailView.vue';
import PagesIndexView from '../views/pages/PagesIndexView.vue';
import PagesDetailView from '../views/pages/PagesDetailView.vue';
import DocumentDetailView from '../views/pages/DocumentDetailView.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: () => import('@/views/layouts/App-layout.vue'),
            redirect: {name: 'ItemsIndexView'},
            children: [
                {
                    path: '/products',
                    name: 'ItemsIndexView',
                    component: ItemsIndexView,
                    meta: {
                        auth: false
                    }
                },
                {
                    path: '/products/:code/detail',
                    name: 'ItemsDetailView',
                    component: ItemsDetailView,
                    meta: {
                        auth: false
                    },
                },
                {
                    path: '/offers',
                    name: 'OffersView',
                    component: OffersView,
                    meta: {
                        auth: false
                    }
                },
                {
                    path: '/items/:code/embedded-chart',
                    name: 'ItemsChartView',
                    component: ItemsChartView,
                    meta: {
                        auth: false
                    }
                },
                {
                    path: '/calculators',
                    name: 'CalculatorsView',
                    component: CalculatorsView,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: '/page-admin',
                    name: 'PageAdminView',
                    component: PageAdminView,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: '/page-admin/:id/detail',
                    name: 'PageAdminDetailView',
                    component: PageAdminDetailView,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: '/pages',
                    name: 'PagesIndexView',
                    component: PagesIndexView,
                },
                {
                    path: '/pages/:slug',
                    name: 'PagesDetailView',
                    component: PagesDetailView,
                },
                {
                    path: '/document/:uuid',
                    name: 'DocumentDetailView',
                    component: DocumentDetailView,
                },
                /*{
                    path: '/users',
                    name: 'UsersIndexView',
                    component: UsersIndexView,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: '/tenants',
                    name: 'TenantsIndexView',
                    component: TenantsIndexView,
                    meta: {
                        auth: true
                    }
                },*/
            ]
        },
        {
            path: '/',
            component: () => import('@/views/layouts/Entrance-layout.vue'),
            redirect: {name: 'Login'},
            children: [
                {
                    path: '/login',
                    name: 'Login',
                    component: LoginView,
                    meta: {
                        auth: false
                    }
                },]
        },
        {
            path: '/embed',
            name: 'ItemsChartEmbedView',
            component: ItemsChartEmbedView,
            meta: {
                auth: false
            }
        }, {
            path: '/embed-calculator',
            name: 'ItemsCalculatorView',
            component: ItemsCalculatorView,
            meta: {
                auth: false
            }
        },
    ]
});

/*router.beforeEach((to, from, next) => {
    // Přidáme query parametr, pokud chybí
    if (!to.query.tenant_code) {
        if (window.tenant_code) {
            next({
                path: to.path,
                query: {...to.query, tenant_code: window.tenant_code},
            });
        }
    } else {
        next();
    }
});*/

export default router;
