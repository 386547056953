<template>
  <div class="mb-3">
    <drop-zone
      :options="dropzoneOptions"
      :headers="dropzoneOptionsHeaders"
      :url="upload_url"
      :acceptedFiles="['jpg', 'jpeg', 'png', 'doc','docx','pdf','txt','xls','xlsx','ppt','pptx']"
      method="POST"
      @uploaded="fileUploaded"
      @sending="fileSending"
    >
      <template v-slot:message>
        <p v-html="$t('page_admin.dropzoneText')"></p>
      </template>

    </drop-zone>
  </div>
</template>

<script>
import http from '@/http';

export default {
  props: ['selectedPageId'],

  data() {
    return {
      dropzoneOptions: {
        maxFiles: 'Number(10000000000)',
        uploadOnDrop: 'true',
        multipleUpload: 'true',
        parallelUpload: '3',
        method: 'POST',
        params: {},
      },
      dropzoneOptionsHeaders: {
        /*'X-XSRF-TOKEN': document.head.querySelector('[name="csrf-token"]').content,
        'X-CSRF-TOKEN': document.head.querySelector('[name="csrf-token"]').content,*/
      },
    };
  },

  computed: {
    upload_url() {
      return http.defaults.baseURL + '/pages/' + this.selectedPageId + '/files/upload';
    },
  },

  methods: {
    fileUploaded() {},
    fileSending(file, xhr, formData) {
      let self = this;
      //formData.append('id', this.selectedPageId ?? null);
      function callback(response) {
        self.emitter.emit('fileUploaded', response);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          callback(xhr.response);
        }
      };
    },
  },
};
</script>
