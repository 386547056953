<template>
  <div>
    <div class="card mb-3">
      <div class="card-header fw-bold">{{ $t('pages.documents') }}</div>
      <div class="card-body">
        <div class="card-text pb-3 mb-2 " v-if="!page?.documents.length">{{ $t('pages.noDocuments') }}</div>
        <div class="card-text" v-else>
          <div class="row pb-2 align-items-center documents--item" v-for="document in page.documents" :key="document.uuid">
            <div class="col-8">
               <span
                   class="me-2"
                   style="vertical-align: middle"
                   :class="'file-icon file-icon__' + document.extension"
               >
                    {{ document.extension }}
                  </span>
              <span class="ml-2">{{ document.file_name }}</span>
            </div>
            <div class="col-4 text-end">
              <router-link
                  :to="{ name: 'DocumentDetailView', params: { uuid: document.uuid } }"
              >
                <button class="btn btn-primary"><i class="bi-eye"></i>
                  </button></router-link
              >
              <button class="btn btn-danger ms-2"><i class="bi bi-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>


  <!--      <v-card-text>

          <div v-for="document in showedDocuments">
            <v-row align="center">
              <v-col class="v-col-auto" text="ed">
                <span
                  style="vertical-align: middle"
                  :class="'file-icon file-icon__' + document.extension"
                >
                  {{ document.extension }}
                </span>
                <span class="ml-2">{{ document.name }}</span>
              </v-col>
              <v-col text="ed" style="text-align: right">
                <router-link
                  v-if="canViewFile(document)"
                  :to="{ name: 'DocumentDetail', params: { hash: document.hash } }"
                >
                  <v-btn variant="flat" size="x-small">
                    <v-icon icon="mdi-eye" color="primary"></v-icon></v-btn></router-link
                ><v-btn variant="flat" @click="documentDownload(document)">
                  <v-icon icon="mdi-file-download" color="primary"></v-icon> </v-btn
                ><v-btn
                  v-if="isAdmin()"
                  variant="flat"
                  size="x-small"
                  @click="documentModalOpen(document.id)"
                >
                  <v-icon icon="mdi-pencil" color="primary"></v-icon></v-btn
              ></v-col>
            </v-row>
            <v-divider class="mt-2 mb-2"></v-divider>
          </div>
          <v-card-actions
            v-if="
              !showMoreDocuments && !showAllDocuments && Object.keys(documents).length > 5
            "
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="flat" v-on:click="getAllDocuments()">{{
              $t('documents.more')
            }}</v-btn></v-card-actions
          >
        </v-card-text>-->
  </div>
</template>

<script>
export default {
  props: ['page'],

  data() {
    return {};
  },

  computed: {
    // a computed getter
    /*showedDocuments() {
      // `this` points to the component instance
      let self = this;
      if (!self.showMoreDocuments && !self.showAllDocuments) {
        return self.documents.slice(0, 5);
      } else return this.documents;
    },*/
  },

  methods: {
    /*documentDownload(doc) {
      http
        .get(doc.url, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let type = response.headers['content-type'];
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: type })
          );
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', doc.name + '.' + doc.extension);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        });
    },*/

    /*getAllDocuments() {
      this.showMoreDocuments = true;
      this.showAllDocuments = true;
      this.getDocuments();
    },*/

    /*getDocuments() {
      let self = this;
      let url = null;

      switch (self.type) {
        case 'dashboard_files':
          url = 'dashboard-files';
          break;
        case 'client_documents':
          url = 'clients/' + self.entityHash + '/files' + '?type=' + self.type;
          break;
        case 'my_documents':
          url = 'clients/' + self.entityHash + '/files' + '?type=' + self.type;
          break;
        case 'investment_documents':
          url = 'investments/' + self.entityHash + '/files';
          self.showAllDocuments = true;
          self.showMoreDocuments = true;
          break;
      }
      http.get(url).then((response) => {
        self.documents = response.data;
      });*/
  },
};
</script>
