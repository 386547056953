<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('pages.document') + ': ' + document?.file_name }}</h3>
      </div>
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a @click="$router.go(-1)">{{ $t('general.back') }}</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-body">
              <!-- pdf -->
              <div
                  class="app-header"
                  v-if="pdfSrc || document?.mime_type == 'application/pdf'"
              >
                <template v-if="isLoading"> Loading...</template>

                <template v-else>
                  <span v-if="showAllPages"> {{ pageCount }} stránka(-y) </span>
                </template>
              </div>

              <div
                  class="app-content"
                  v-if="pdfSrc || document?.mime_type == 'application/pdf'"
              >
                <div class="pdf-controls">
                  Zoom: {{ zoom }}%
                  <button
                      class="ml-3"
                      @click="updateZoom(+25)"
                      v-bind:class="{ disabled: zoom >= maxZoom }"
                  >
                    Zoom +
                  </button>
                  <button
                      class="ml-3"
                      @click="updateZoom(-25)"
                      v-bind:class="{ disabled: zoom <= minZoom }"
                  >
                    Zoom -
                  </button>
                  <button
                      class="ml-3"
                      @click="updateZoom(100)"
                      v-bind:class="{ disabled: zoom == minZoom }"
                  >
                    100 %
                  </button>
                </div>
                <div class="pdf-viewer-wrapper" :class="{ 'zoom-active': zoom > 100 }">
                  <vue-pdf-embed
                      v-if="pdfSrc"
                      ref="pdfRef"
                      :source="pdfSrc"
                      :page="page"
                      @rendered="handleDocumentRender"
                  />
                </div>
              </div>

              <!-- images -->
              <div v-if="document && document.mime_type.includes('image/')" class="w-100">
                <img
                    v-bind:src="'data:image/jpeg;base64,' + document.base64"
                    class="document--image"
                    alt="Obrázek"
                />
              </div>

              <!-- Office documents -->
              <div
                  v-else-if="
                    document &&
                    document.mimetype ==
                      ('application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        'application/vnd.ms-powerpoint' ||
                        'application/vnd.ms-excel' ||
                        'application/msword')
                  "
              >
                <div class="o365-iframe-wrapp">
                  <iframe
                      :src="
                        'https://view.officeapps.live.com/op/embed.aspx?src=' +
                        document?.url
                      "
                      frameborder="0"
                      width="100%"
                      height="100%"
                      class="o365-iframe"
                  ></iframe>
                </div>
              </div>

            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  components: {
    VuePdfEmbed,
  },

  data() {
    return {
      document: null,
      isLoading: true,
      page: null,
      pageCount: 1,
      pdfSource: '',
      showAllPages: true,
      zoom: 100,
      minZoom: 100,
      maxZoom: 250,
    };
  },

  mounted() {
    this.getDocument();
  },

  computed: {
    pdfSrc() {
      if (
          this.document &&
          this.document.mime_type == 'application/pdf' &&
          this.document.base64
      ) {
        return {
          data: window.atob(this.document.base64),
        };
      }
      return null;
    },
  },

  methods: {
    getDocument() {
      let self = this;
      http.get('/files/' + this.$route.params.uuid).then((response) => {
        self.document = response.data.document;
      });
    },
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    updateZoom(value) {
      if (value == 100) {
        return (this.zoom = value);
      }
      if (this.zoom + value < this.minZoom || this.zoom + value > this.maxZoom) {
        return;
      }
      this.zoom += value;
    },


  }
};
</script>

<style>
.pdf-viewer-wrapper {
  width: 100%;
  overflow: auto;
}

.vue-pdf-embed {
  width: 100%;
  height: auto;
  transform: scale(v-bind('zoom/100'));
  transform-origin: 0 0;
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  padding: 24px 16px;
}

.right {
  float: right;
}

.pdf-viewer-wrapper {
  /* overflow: hidden;
  max-height: 90vh; */
}

.pdf-viewer-wrapper.zoom-active {
  cursor: grab;
}

/* resize-sensor.vue */
@keyframes resizeSensorVisibility {
  from {
    top: 0;
  }
}

button.disabled {
  opacity: 0.6;
  cursor: default;
}

/* pdf.vue */
.annotationLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.annotationLayer section {
  position: absolute;
}

.annotationLayer .linkAnnotation > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer .linkAnnotation > a /* -ms-a */
{
  background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') 0 0 repeat;
}

.annotationLayer .linkAnnotation > a:hover {
  opacity: 0.2;
  background: #ff0;
  box-shadow: 0px 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
}

.annotationLayer .textWidgetAnnotation input,
.annotationLayer .textWidgetAnnotation textarea,
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  padding: 0 3px;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none;
}

.annotationLayer .textWidgetAnnotation input[disabled],
.annotationLayer .textWidgetAnnotation textarea[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
.annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation input:hover,
.annotationLayer .textWidgetAnnotation textarea:hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid #000;
}

.annotationLayer .textWidgetAnnotation input:focus,
.annotationLayer .textWidgetAnnotation textarea:focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 1px solid transparent;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 115%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.annotationLayer .popupWrapper {
  position: absolute;
  width: 20em;
}

.annotationLayer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: #ffff99;
  box-shadow: 0px 2px 5px #333;
  border-radius: 2px;
  padding: 0.6em;
  margin-left: 5px;
  cursor: pointer;
  word-wrap: break-word;
}

.annotationLayer .popup h1 {
  font-size: 1em;
  border-bottom: 1px solid #000000;
  padding-bottom: 0.2em;
}

.annotationLayer .popup p {
  padding-top: 0.2em;
}

.annotationLayer .highlightAnnotation,
.annotationLayer .underlineAnnotation,
.annotationLayer .squigglyAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.o365-iframe-wrapp {
  position: relative;
}

.o365-iframe-wrapp:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
  background: #fff;
  display: block !important;
  content: '';
}
</style>